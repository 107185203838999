import { useSelector } from 'react-redux';
import useTranslate from 'lib/languages/useTranslate';

import { getAccount } from 'lib/selectors';
import Button, { ButtonTypes } from '../../generic/button/presentational/Button';
import Typography from '../../generic/typography/presentational/Typography';
import ModalOuterContainer from '../generic/containers/ModalOuterContainer';
import { IAccount } from 'lib/type/types';
import { useTranslatedFeePrompt } from 'lib/hooks'

interface FeesModalProps {
    action: string;
    fee: number;
    standingOrderRecurranceFee?: number;
    additionalCost?: number;
    handleSubmit: () => void;
    closeModal: () => void;
    isInternalPayee?: boolean;
}

const FeesModal = ({
    action,
    fee,
    standingOrderRecurranceFee,
    additionalCost = 0,
    handleSubmit,
    closeModal,
    isInternalPayee
}: FeesModalProps) => {
    const { availableBalance, productType } = useSelector(getAccount) as IAccount;
    const translate = useTranslate('feesModal');
    const feePromptText = useTranslatedFeePrompt(
        translate,
        action,
        fee,
        standingOrderRecurranceFee,
        productType,
        isInternalPayee
    );
    return (
        <ModalOuterContainer
            className="add-cardholder-fee-modal"
            handleSubmit={handleSubmit}
            hideModal={closeModal}
            isPosting={false}
            customButtons={+availableBalance < fee + additionalCost}
        >
            {+availableBalance < fee + additionalCost ? (
                <>
                    <Typography tag="h1">
                        {translate('insufficientFunds')}
                    </Typography>
                    <Typography className="description">
                        {translate('noFunds')}
                    </Typography>
                    <Button type={ButtonTypes.SUBMIT} onClick={closeModal}>
                        {translate('ok')}
                    </Button>
                </>
            ) : (
                <>
                    <Typography tag="h1">{translate('newFee')}</Typography>
                    <Typography className="description">
                        {feePromptText}
                    </Typography>
                </>
            )}
        </ModalOuterContainer>
    );
};

export default FeesModal;
