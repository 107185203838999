import React from 'react';
import FeesModal from '../feesModal/FeesModal';
import { FeesAction } from 'lib/type/enums';

function PaymentFeeModal({ fee, additionalCost, handleSubmit, closeModal, isInternalPayee }) {
    return (
        <FeesModal
            action={FeesAction.PAYMENT}
            fee={fee}
            additionalCost={additionalCost}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            isInternalPayee={isInternalPayee}
        />
    );
}

export default PaymentFeeModal;
