import React from 'react';
import FeesModal from '../feesModal/FeesModal';
import { FeesAction } from 'lib/type/enums';

const StandingOrderFeeModal = ({
    fee,
    recurranceFee,
    handleSubmit,
    closeModal,
    isInternalPayee
}) => {
    return (
        <FeesModal
            action={FeesAction.STANDING_ORDER}
            fee={fee}
            standingOrderRecurranceFee={recurranceFee}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            isInternalPayee={isInternalPayee}
        />
    );
}

export default StandingOrderFeeModal;
