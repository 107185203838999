import * as Sentry from "@sentry/react";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAccount } from 'lib/selectors';
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lib/utils';
import { IAccount } from 'lib/type/types'
import { REACT_APP_SENTRY_ENV, REACT_APP_SENTRY_DSN, REACT_APP_ENV } from 'config';
import pckg from '../../package.json';

export const initSentry = () => {
    const enabledForDev = REACT_APP_ENV === 'development' || REACT_APP_ENV === 'staging';

    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN,
        environment: REACT_APP_SENTRY_ENV,
        integrations: [
            Sentry.browserTracingIntegration()
        ],
        release: pckg.version,
        beforeSend(event) {
            // can filter events in here by returning null.
            return event;
        },
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        enabled: enabledForDev
    });
}

export const useSetSentryUser = () => {
    const accessToken = localStorage.getItem('accessToken');
    const cognitoToken = localStorage.getItem('cognitoToken');
    const jwtToken = accessToken ?? cognitoToken;
    const account = useSelector(getAccount) as IAccount;

    useEffect(() => {
        if (jwtToken) {
            const decoded = jwtDecode(jwtToken);
            Sentry.setUser({
                accountId: decoded.accountIdV1 ?? decoded.accountIdV2,
                username: decoded.usernameV1 ?? '',
                email: decoded.username,
                isActive: decoded.isActive ?? false
            });
            if (decoded.accountIdV1) {
                Sentry.setTag('ppsAcccountNumber', decoded.accountIdV1 ?? '');
            } else if (decoded.accountIdV2) {
                Sentry.setTag('tribeAccountNumber', decoded.accountIdV2);
            }
        } // NOTE: This is commented out for now, until we have a better understanding of what data we want to send to Sentry
    }, [jwtToken]);

    useEffect(() => {
        if (!isEmpty(account)) {
            Sentry.setContext('Account Info', {
                isDormant: account.isDormant,
                productType: account.productType,
                productTypeDesc: account.productTypeDesc,
                currencyCode: account.clearedBalanceCurrencyCode,
                status: account.status,
            });

            Sentry.setTag('productType', account.productType);
            Sentry.setTag('currencyCode', account.clearedBalanceCurrencyCode);
        }
    }, [account]);
};

export const sentryErrorCb = err => {
    try {
        const status = err.response?.status;
        if (status === 400) return;

        const ex = new Error(`API Request failed with status ${status}`);

        Sentry.setExtra('statusCode', status);
        Sentry.setExtra('response', err.response);

        Sentry.setExtra('requestUrl', err.config?.url);
        Sentry.setExtra('requestMethod', err.config?.method.toUpperCase());
        Sentry.setExtra('requestHeaders', err.config?.headers);
        Sentry.setExtra('requestBody', parseConfigData(err));

        Sentry.captureException(ex);
    } catch (e) {
        Sentry.captureException(e);
    }
};

const parseConfigData = err => {
    try {
        if (!err.config?.data) return {};
        const { data } = err.config;

        if (typeof data === 'string') return JSON.parse(data);
        return data;
    } catch (e) {
        return {};
    }
};

